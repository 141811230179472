var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"mb-2"},[_vm._v(" Create a new booking ")])])],1),_c('b-row',[_c('b-col',[(_vm.isLoading)?_c('div',{staticClass:"text-center py-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_c('validation-observer',{ref:"createValidation"},[_c('b-form',{staticClass:"d-flex flex-column",on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Boat:","label-for":"select-boat"}},[_c('v-select',{attrs:{"id":"select-boat","placeholder":"Select package","label":"name","options":_vm.boatNamesTripsPictures,"clearable":false},model:{value:(_vm.form.boat),callback:function ($$v) {_vm.$set(_vm.form, "boat", $$v)},expression:"form.boat"}})],1),_c('b-form-group',{attrs:{"label":"Charter Package:","label-for":"select-charter-package"}},[_c('validation-provider',{attrs:{"name":"charter package","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"select-charter-package","placeholder":"Select package","label":"text","options":_vm.charterPackages,"clearable":false},model:{value:(_vm.form.charterPackage),callback:function ($$v) {_vm.$set(_vm.form, "charterPackage", $$v)},expression:"form.charterPackage"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Date:","label-for":"input-date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"input-date","min":_vm.minDate,"date-disabled-fn":_vm.dateDisabled,"disabled":_vm.isDatepickerDisabled,"placeholder":_vm.datepickerPlaceholder,"locale":"en"},on:{"input":_vm.datepickerUpdated},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.form.boat.id === _vm.TEASER_ID && (_vm.form.charterPackage === _vm.HALF_DAY_TRIP || _vm.form.charterPackage === _vm.HARBOR_CRUISE) && _vm.form.date !== null)?[_c('b-form-group',{attrs:{"label":"Time:","label-for":"input-time"}},[_c('validation-provider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"select-time","placeholder":"Select time","label":"text","options":_vm.times,"clearable":false,"selectable":_vm.timesDisabled,"disabled":_vm.isDatepickerDisabled},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('small',{staticClass:"text-danger d-block mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1187187072)})],1)]:_vm._e(),_c('b-form-group',{attrs:{"label":"Customer:","label-for":"select-customer"}},[_c('validation-provider',{attrs:{"name":"customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"select-customer","placeholder":"Select customer","label":"name","options":_vm.customers,"clearable":false},model:{value:(_vm.form.customer),callback:function ($$v) {_vm.$set(_vm.form, "customer", $$v)},expression:"form.customer"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"How Many People Will Be In This Group?:","label-for":"input-details"}},[_c('b-form-input',{attrs:{"id":"input-details","placeholder":"0","type":"number"},model:{value:(_vm.form.details),callback:function ($$v) {_vm.$set(_vm.form, "details", $$v)},expression:"form.details"}})],1),_c('b-button',{staticClass:"align-self-end w-xs-100 w-25",attrs:{"type":"submit","variant":"primary","disabled":_vm.isCreateLoading}},[(_vm.isCreateLoading)?_c('b-spinner',{attrs:{"label":"Spinning","small":""}}):_c('span',[_vm._v(" Create ")])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card>
    <b-row>
      <b-col>
        <h1 class="mb-2">
          Create a new booking
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          v-if="isLoading"
          class="text-center py-2"
        >
          <b-spinner label="Loading..." />
        </div>
        <validation-observer
          v-else
          ref="createValidation"
        >
          <b-form
            class="d-flex flex-column"
            @submit="onSubmit"
          >
            <b-form-group
              label="Boat:"
              label-for="select-boat"
            >
              <v-select
                id="select-boat"
                v-model="form.boat"
                placeholder="Select package"
                label="name"
                :options="boatNamesTripsPictures"
                :clearable="false"
              />
            </b-form-group>
            <b-form-group
              label="Charter Package:"
              label-for="select-charter-package"
            >
              <validation-provider
                #default="{ errors }"
                name="charter package"
                rules="required"
              >
                <v-select
                  id="select-charter-package"
                  v-model="form.charterPackage"
                  placeholder="Select package"
                  label="text"
                  :options="charterPackages"
                  :clearable="false"
                />
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Date:"
              label-for="input-date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-datepicker
                  id="input-date"
                  v-model="form.date"
                  :min="minDate"
                  :date-disabled-fn="dateDisabled"
                  :disabled="isDatepickerDisabled"
                  :placeholder="datepickerPlaceholder"
                  locale="en"
                  @input="datepickerUpdated"
                />
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <template v-if="form.boat.id === TEASER_ID && (form.charterPackage === HALF_DAY_TRIP || form.charterPackage === HARBOR_CRUISE) && form.date !== null">
              <b-form-group
                label="Time:"
                label-for="input-time"
              >
                <validation-provider
                  #default="{ errors }"
                  name="time"
                  rules="required"
                >
                  <v-select
                    id="select-time"
                    v-model="form.time"
                    placeholder="Select time"
                    label="text"
                    :options="times"
                    :clearable="false"
                    :selectable="timesDisabled"
                    :disabled="isDatepickerDisabled"
                  />
                  <small class="text-danger d-block mt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <b-form-group
              label="Customer:"
              label-for="select-customer"
            >
              <validation-provider
                #default="{ errors }"
                name="customer"
                rules="required"
              >
                <v-select
                  id="select-customer"
                  v-model="form.customer"
                  placeholder="Select customer"
                  label="name"
                  :options="customers"
                  :clearable="false"
                />
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="How Many People Will Be In This Group?:"
              label-for="input-details"
              class="mb-2"
            >
              <b-form-input
                id="input-details"
                v-model="form.details"
                placeholder="0"
                type="number"
              />
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              class="align-self-end w-xs-100 w-25"
              :disabled="isCreateLoading"
            >
              <b-spinner
                v-if="isCreateLoading"
                label="Spinning"
                small
              />
              <span v-else>
                Create
              </span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BSpinner,
  BFormDatepicker,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { toastSuccess, toastFailure } from '@/libs/toastification'
import axios from '@/libs/axios'
import {checkReservedDate, isTimeSelectable} from "@/libs/reserved-dates";
import {HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID} from "@/constants/trip-types.enum";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    vSelect,
    BFormDatepicker,
  },
  data() {
    return {
      isLoading: true,
      form: {
        boat: null,
        boatObject:null,
        charterPackage: null,
        beforeCharterPackage: null,
        date: null,
        time: null,
        customer: null,
        details: null,
        trips:null
      },
      minDate: new Date(),
      datepickerPlaceholder: 'No date selected',
      isDatepickerDisabled: false,
      blockDates: [],
      required,
      email,
      HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID,
      isCreateLoading: false,
      firstApril2022: new Date(2022, 4, 1),
      thisBoatTripNameId:[],
    }
  },
  computed: {
    ...mapGetters({
      boatNamesTripsPictures: 'boats/boatNamesTripsPictures',
      customers: 'customers/customers',
    }),
    charterPackages() {

      const selectedBoatTripNameId = [];
      const packages = [];
      let that = this;

      this.boatNamesTripsPictures[this.form.boat.id - 1].trips.map(function(trip){

        selectedBoatTripNameId[trip.name] = trip.id;
        if( trip.inactive === 0 ) {
          packages.push(trip.name);
        }
      });

      this.thisBoatTripNameId = selectedBoatTripNameId;

      return packages
    },
    times() {
      const timeOptions = [
        {
          value: '06:00:00',
          text: '6 AM',
          selectable: isTimeSelectable("6 AM", this.blockDates, this.form),
        },
        {
          value: '12:00:00',
          text: '12 PM',
          selectable: isTimeSelectable("12 PM", this.blockDates, this.form),
        }
      ];

      if (this.form.charterPackage === HARBOR_CRUISE) {
        timeOptions.push({
          value: '18:00:00',
          text: '6 PM',
          selectable: isTimeSelectable("6 PM", this.blockDates, this.form),
        });
      }

      return timeOptions;
    },
  },
  watch: {
    'form.boat': function () {
      this.loadBlockDates()
      this.form.charterPackage = null
    },
    'form.charterPackage': function () {
      this.loadBlockDates()
    },
  },
  async mounted() {
    await Promise.all([this.loadCustomers(), this.loadBoatNamesTripsPictures()])
      .then(() => {

        this.form.boat = this.boatNamesTripsPictures ? this.boatNamesTripsPictures[0] : null
        this.isLoading = false
      })
      .catch(() => { toastFailure('Something went wrong in general') })
  },
  methods: {
    ...mapActions({
      createBookingManually: 'bookings/createBookingManually',
      loadBoatNamesTripsPictures: 'boats/loadBoatNamesTripsPictures',
      loadCustomers: 'customers/loadCustomers',
    }),
    onSubmit(event) {
      event.preventDefault()
      this.$refs.createValidation.validate().then(success => {
        if (success) {
          this.isCreateLoading = true
          this.submitForm()
        }
      })
    },
    submitForm() {
      this.createBookingManually({
        user_id: this.form.customer.id,
        user_name: this.form.customer.name,
        user_email: this.form.customer.email,
        boat_id: this.form.boat.id,
        date: this.form.date,
        status: 0,
        charter_package: this.form.charterPackage,
        time: this.form.time ? this.form.time.value : this.getDefaultStartTime(),
        details: this.form.details,
        trip_id: this.thisBoatTripNameId[this.form.charterPackage]
      })
        .then(() => {
          toastSuccess('A new booking has been created')
          this.$router.replace({ name: 'bookings' })
        })
        .catch(() => {
          toastFailure('Something went wrong while creating a booking')
        })
        .finally(() => {
          this.isCreateLoading = false
        })
    },
    dateDisabled(date) {
      return checkReservedDate(date, this.blockDates, this.form, this.boatNamesTripsPictures, this.thisBoatTripNameId);
    },
    disableSelectedDate(){
      this.boat.date = null;
      this.datepickerPlaceholder = 'Please select a new date';
    },
    loadBlockDates() {
      if (this.form.boat && this.form.charterPackage) {
        this.form.date = null
        this.datepickerPlaceholder = 'Loading...'
        this.isDatepickerDisabled = true
        axios.get(`api/bookings/block-dates/${this.form.boat.id}/${this.form.charterPackage}`)
          .then(response => {

            this.datepickerPlaceholder = 'Please choose the date';
            const blockDates = {}

            for (const date of response.data) {

              if( this.form.date == date.date ){
                this.disableSelectedDate();
              }

              if (!blockDates[date.date]) {
                blockDates[date.date] = new Array();
              }

              blockDates[date.date].push(date);
            }

            this.blockDates = blockDates;
            this.datepickerPlaceholder = 'No date selected';
            this.isDatepickerDisabled = false;

            if( this.dateDisabled(this.form.date)  ){
              this.disableSelectedDate();
            }
          })
          .catch((error) => {
            console.log(error);
            toastFailure('Something went wrong while loading reserved dates')
          })
      }
    },
    datepickerUpdated() {
      this.form.time = null
    },
    isSelectableBlocked(time) {
      if (this.blockDates[this.form.date]) {
        /* eslint-disable-next-line */
        return this.blockDates[this.form.date].includes(time) ? false : true
      }
      return true
    },
    timesDisabled(option) {
      return option.selectable
    },
    getDefaultStartTime() {
      return this.form.boat.id === TEASER_ID ? "06:00:00" : "05:00:00";
    }
  },
}
</script>

<style>

</style>
